import { Center, Heading, Stack, Text } from "@chakra-ui/react";

export const RestrictedPage = () => {
  return (
    <Center minH="100vh" textAlign="center">
      <Stack>
        <Heading>Access Restricted</Heading>
        <Text>
          Access to our platform is unavailable in your region. This restriction
          is in compliance with applicable laws and regulations.
        </Text>
      </Stack>
    </Center>
  );
};
