import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Address } from "viem";
import { useAccount, useChainId } from "wagmi";

type UserAlienRewardsResponse = {
  contract: string;
  root: string;
  account: string;
  claimed: string;
  totalAmount: string;
  proof: `0x${string}`[];
  timestamp: number;
  chainId: number;
};

type UseUserAlienRewardsData = {
  contract: Address;
  root: string;
  account: string;
  claimed: bigint;
  totalAmount: bigint;
  claimable: bigint;
  proof: `0x${string}`[];
  timestamp: number;
};

export function useUserAlienRewards() {
  const { address } = useAccount();
  const chainId = useChainId();

  return useQuery({
    queryKey: ["alien-rewards", address, chainId],
    queryFn: async (): Promise<UseUserAlienRewardsData> => {
      const { data } = await axios.get<UserAlienRewardsResponse[]>(
        "https://api.alien.finance/api/v1/reward/claim-info",
        {
          params: {
            account: address,
          },
        },
      );

      const datum = data.find((d) => d.chainId === chainId);
      if (!datum) {
        throw new Error("No data found");
      }

      const claimed = BigInt(datum.claimed);
      const totalAmount = BigInt(datum.totalAmount);

      return {
        contract: datum.contract as Address,
        root: datum.root,
        account: datum.account,
        claimed: BigInt(datum.claimed),
        totalAmount: BigInt(datum.totalAmount),
        claimable: claimed <= totalAmount ? totalAmount - claimed : 0n,
        proof: datum.proof,
        timestamp: datum.timestamp,
      };
    },
    enabled: !!address,
  });
}
