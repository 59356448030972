import Table from "@/components/Table";
import {
  HistoryItem,
  useLevXHistory,
} from "@/features/leverage/hooks/useLevXHistory.ts";
import { usePool } from "@/hooks/usePool.ts";
import { usePoolMarkets } from "@/hooks/usePoolMarkets.ts";
import { formatBigInt } from "@/utils/format.ts";
import { HStack, Text, Tooltip } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { format } from "date-fns";
import { formatUnits } from "viem";
import { EmptyTableText } from "./EmptyTableText";
import { TokenView } from "./TokenView";

export const HistoryView = () => {
  const { pool } = usePool();
  const { data } = useLevXHistory({ pool });
  const { marketsByAddress, isLoading: isMarketsLoading } =
    usePoolMarkets(pool);

  const columnHelper = createColumnHelper<HistoryItem>();

  const getHistoryItemPrice = (item: HistoryItem) => {
    const {
      detail: { longAsset, longAmount, shortAsset, shortAmount },
    } = item;
    const formattedLongAmount = formatUnits(
      BigInt(longAmount),
      marketsByAddress[longAsset].marketDecimals,
    );
    const formattedShortAmount = formatUnits(
      BigInt(shortAmount),
      marketsByAddress[shortAsset].marketDecimals,
    );
    return Number(formattedLongAmount) / Number(formattedShortAmount);
  };

  const renderTokenAmount = (
    historyItem: HistoryItem,
    side: "long" | "short",
  ) => {
    const amount =
      side === "long"
        ? historyItem.detail.longAmount
        : historyItem.detail.shortAmount;
    const asset =
      side === "long"
        ? historyItem.detail.longAsset
        : historyItem.detail.shortAsset;
    const market = marketsByAddress[asset];
    const sign = historyItem.type === "increase_position" ? "+" : "-";

    return (
      <HStack>
        <TokenView symbol={market.marketSymbol} />
        <Tooltip
          hasArrow
          label={formatUnits(BigInt(amount), market.marketDecimals)}
        >
          <Text fontSize={12}>
            {sign}
            {formatBigInt(BigInt(amount), market.marketDecimals, 2)}
          </Text>
        </Tooltip>
      </HStack>
    );
  };

  const columns = [
    columnHelper.accessor("detail.longAsset", {
      header: () => <Text>Long</Text>,
      cell: ({ row: { original } }) => renderTokenAmount(original, "long"),
    }),
    columnHelper.accessor("detail.shortAsset", {
      header: () => <Text>Short</Text>,
      cell: ({ row: { original } }) => renderTokenAmount(original, "short"),
    }),
    columnHelper.accessor(
      (row) => {
        const price = getHistoryItemPrice(row);
        return price.toFixed(6);
      },
      {
        id: "price",
        header: () => <Text>Price</Text>,
        cell: ({
          getValue,
          row: {
            original: {
              detail: { longAmount, longAsset, shortAmount, shortAsset },
            },
          },
        }) => {
          const longMarket = marketsByAddress[longAsset];
          const shortMarket = marketsByAddress[shortAsset];
          const longAmountStr = formatBigInt(
            BigInt(longAmount),
            longMarket.marketDecimals,
            2,
          );
          const shortAmountStr = formatBigInt(
            BigInt(shortAmount),
            shortMarket.marketDecimals,
            2,
          );
          const tooltipLabel = `${longAmountStr} / ${shortAmountStr}`;

          return (
            <Text fontWeight={600} fontSize={14}>
              <Tooltip label={tooltipLabel} placement="top-start" hasArrow>
                {getValue()}
              </Tooltip>
            </Text>
          );
        },
      },
    ),
    columnHelper.accessor("timestamp", {
      header: () => <Text>Time</Text>,
      cell: ({ getValue }) => (
        <Text fontWeight={600} fontSize={14}>
          {format(getValue() * 1000, "HH:mm, MMM d")}
        </Text>
      ),
    }),
  ];

  if (isMarketsLoading) {
    return null;
  }

  if (data?.length === 0) {
    return <EmptyTableText pt={4}>There is no leverage history</EmptyTableText>;
  }

  return (
    <Table
      columns={columns}
      data={data?.slice(0, 5) || []}
      thProps={{
        textColor: "color.label",
        px: 2,
        py: 1,
      }}
      tdProps={{
        px: 2,
        py: 3,
      }}
    />
  );
};
