import { Pool } from "@/configs/chains.ts";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Address } from "viem";
import { useAccount, useChainId } from "wagmi";

export type HistoryItem = {
  type: "increase_position" | "decrease_position";
  detail: {
    longAsset: Address;
    longAmount: string;
    shortAsset: Address;
    shortAmount: string;
  };
  timestamp: number;
};

export type UseLevXHistoryParams = {
  pool: Pool;
};

export const useLevXHistory = ({ pool }: UseLevXHistoryParams) => {
  const { address } = useAccount();
  const chainId = useChainId();

  const poolAddress = pool.contracts.alien;

  return useQuery({
    queryKey: ["useLevXHistory", address, poolAddress],
    queryFn: async () => {
      if (!address) {
        return [];
      }
      const response = await axios.get<HistoryItem[]>(
        "https://api.alien.finance/api/v1/activity",
        {
          params: {
            account: address,
            pool: poolAddress,
            chainId,
          },
        },
      );
      return response.data;
    },
  });
};
